import React, { Suspense } from "react";
import { Amplify } from "aws-amplify";
import 'normalize.css';
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import awsExports from "./aws-exports";
import { amplifyConfig } from "./config/amplify";
import { getOptions } from "./util/functions";
import { createRoot } from "react-dom/client";

const Chatbot = React.lazy(() => import('./Chatbot'));
const App = React.lazy(() => import('./App'));

let { fullView, darkMode } = getOptions()

Amplify.configure({ ...awsExports, ...amplifyConfig });

const root = createRoot(document.getElementById("root"));

root.render(
    <Suspense fallback={
        <div style={{ padding: '1rem', color: 'white' }}>
            Loading...
        </div>}
    >
        {fullView ?
            <Chatbot fullView={fullView} darkMode={darkMode} />
            : <App darkMode={darkMode} />}
    </Suspense>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
