function formatDateMessage(date) {
    let dateTime = !!date ? new Date(date) : new Date()
    
    const datePart = `${dateTime.toLocaleDateString(undefined, {month: "short", day: "numeric"})}`;
    const timePart = `${dateTime.toLocaleTimeString(undefined, {hour: '2-digit', minute:'2-digit'})}`;
    const result = `${datePart} - ${timePart}`;

    return result
}

function getOptions() {
    let searchParams = parseParams(decodeURIComponent(window.location.search), '?')
    let hashParams = parseParams(decodeURIComponent(window.location.hash), '#')

    return {
        ...searchParams,
        ...hashParams
    }
}

function parseParams(string, selector) {
    if(!string) return {}
    let params = {}
    let splitQuery = string.split(selector)[1]
    splitQuery = splitQuery.split('&')

    splitQuery.forEach(param => {
        const parts = param.split('=')
        if (parts.length === 2) {
            let value = parts[1]
            params[parts[0]] = value === 'true' ? true : value === 'false' ? false : value
        }
    })

    return params
}

export { formatDateMessage, getOptions, parseParams }