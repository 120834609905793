const botConfig = require('./.bot-config.json');

function constructBotConfig() {
  let missingFields = [];
  let requiredFields = ["name", "alias", "region"];

  requiredFields.forEach((field) => {
    if (!botConfig[field]) {
      missingFields.push(field);
    }
  });

  if (missingFields.length > 0) {
    throw new Error(
      `Missing fields from bot config: ${missingFields.join(", ")}`
    );
  }

  let bots = {
    [botConfig.name]: {
      name: botConfig.name,
      alias: botConfig.alias,
      region: botConfig.region,
    },
  };

  return bots;
}

export { constructBotConfig }