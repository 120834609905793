import { constructBotConfig } from "./bots";

export const amplifyConfig = {
    Auth: {
        identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL,
        region: process.env.REACT_APP_AUTH_REGION
    },
    Interactions: {
        bots: constructBotConfig()
    }
}